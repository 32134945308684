@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Archivo", sans-serif;
}

.navbar-nav .nav-link {
  color: #000;
}
.navbar-nav .nav-item {
  padding: 10px;
}

.aditya_birla {
  height: 55px !important;
  width: auto;
}
.birla_estate {
  height: 50px !important;
  width: auto;
}
.herosection {
  margin-top: 50px;
}
.herosection h1 {
  font-size: 50px;
  font-weight: 800;
  color: #850b6f;
}
.herosection b {
  font-weight: 800;
}
.herosection img {
  height: 600px;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 0px 0px #850b6f;
  box-shadow: 20px 20px 0px -6px #850b6f;
}
.herosection small {
  opacity: 0.4;
}
.herosection .disclimer {
  color: rgba(0, 0, 0, 0.503);
  font-size: 11px;
  padding-top: 80px;
}
.btn {
  background-color: #850b6f;
  color: #fff;
  border: 2px solid #850b6f !important;
}
.btn:hover {
  background-color: #850b6f !important;
}
.btn:active,
.btn:focus {
  background-color: #fff !important;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  border: 2px solid #850b6f !important;
  color: #850b6f !important;
}

@media (max-width: 800px) {
  .aditya_birla {
    height: 50px !important;
    width: auto;
  }
  .birla_estate {
    height: 60px !important;
    width: auto;
  }
  .herosection img {
    margin-top: 20px;
  }
  .header .img-fluid {
    max-width: none;
  }
  .herosection .disclimer {
    padding-top: 40px;
  }
}

.model_pageload .good_news {
  font-size: 20px;
  font-weight: 600;
}
.model_pageload .phase_two {
  font-size: 30px;
  font-weight: bold;
}
.model_pageload img {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 150px;
}
.model_pageload h1 {
  font-weight: 800;
}

.hero_carosel .owl-nav button.owl-prev,
.hero_carosel .owl-nav button.owl-next {
  position: absolute;
  bottom: 50%;
  right: 50%;
  color: #000;
}
.hero_carosel .owl-nav button.owl-prev {
  left: 0;
}
.hero_carosel .owl-nav button.owl-next {
  right: 0;
}
.hero_carosel.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
  color: #444;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.hero_carosel.owl-carousel.owl-theme.owl-loaded a {
  text-decoration: none;
}
.hero_carosel i.owl-direction {
  margin: 0 13px;
  font-size: 3em;
  padding: 0;
  color: #607d8b;
}
.hero_carosel .owl-nav button.owl-next,
.hero_carosel .owl-nav button.owl-prev {
  outline: 0;
  background: #fff;
  width: 36px;
}
.tag {
  display: inline-block;
}

.hero_carosel .carsl_cnt {
  display: inline-block;
  padding: 20px 30px;
  background: rgba(0, 0, 0, 0.3);
  font-size: 25px;
}

@media (max-width: 700px) {
  .hero_carosel .carsl_cnt {
    font-size: 18px;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}
.desc {
  position: absolute;
  padding: 8%;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.hero-img {
  position: relative;
}
.hero-img:before {
  background: rgba(0, 0, 0, 0.38);
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.btn-gradient-bg {
  color: #fff;
  display: inline-block;
  padding: 11px 33px;
  background: linear-gradient(to right, #f05874 0%, #fc6a2a 100%);
  border-radius: 30px;
}
.delay-1 {
  animation-delay: 0.2s;
}
.delay-2 {
  animation-delay: 0.4s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.8s;
}
.slider-counter {
  z-index: 99;
  bottom: 1%;
  position: absolute;
  text-align: center;
  right: 4%;
  font-size: 120px;
  color: rgba(242, 242, 242, 0.7);
  font-weight: 700;
}
@media (max-width: 767px) {
  .slider-counter {
    font-size: 80px;
    font-weight: 600;
    bottom: 0;
  }
}
@media (max-width: 576px) {
  .slider-counter {
    font-size: 30px;
    font-weight: 500;
    bottom: 0;
  }
}
/*Credit*/

.about {
  padding-top: 40px;
  padding-bottom: 40px;

  background-color: #f8f8f8;
  margin-top: 50px;
}
.about h2 {
  text-align: center;
  font-size: 30px;
}
.about p {
  padding-top: 30px;
  opacity: 0.7;
}

.project_highlights {
  padding-top: 30px;
  padding-bottom: 30px;
}
.project_highlights .col-6 {
  padding: 0px;
}
.project_highlights h2 {
  text-align: center;
  padding-bottom: 20px;
}
.project-highlights.section {
  padding-bottom: 0;
}
.pd-list {
  position: relative;
  overflow: hidden;
  cursor: default;
}
.pd-list img {
  width: 100%;
}
.pd-list::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ededed;
  top: 0;
  left: 0;
  opacity: 1;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}
.project-highlights .col-6:nth-child(even) .pd-list::before {
  background: #f5f5f5;
}
.pd-list .pd-list-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.pd-list .pd-list-content .pdl-count {
  font-size: 10rem;
  font-weight: 600;
  line-height: 0.9;
  background: url(../public/images/Project_Highlights/bg-res-proj-detail-01.jpg)
    no-repeat center center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.ojasvi .pd-list .pd-list-content .pdl-count.count-1 {
  background: url("../public/images/Project_Highlights/bg-res-proj-detail-01.jpg")
    no-repeat center center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ojasvi .pd-list .pd-list-content .pdl-count.count-2 {
  background: url("../public/images/Project_Highlights/bg-res-proj-detail-02.jpg")
    no-repeat center center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ojasvi .pd-list .pd-list-content .pdl-count.count-3 {
  background: url("../public/images/Project_Highlights/bg-res-proj-detail-03.jpg")
    no-repeat center center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ojasvi .pd-list .pd-list-content .pdl-count.count-4 {
  background: url("../public/images/Project_Highlights/bg-res-proj-detail-04.jpg")
    no-repeat center center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pd-list .pd-list-content .pdl-count span {
  font-size: 6rem;
  vertical-align: top;
}
.pd-list .pd-list-content p {
  font-size: 24px;
  color: #616161;
  line-height: 1.2;
  margin-bottom: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}
.pd-list:hover::before {
  opacity: 0;
}
.pd-list:hover .pd-list-content .pdl-count {
  background: none;
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #fff !important;
  text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
  -webkit-text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
  -moz-text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
}
.pd-list:hover .pd-list-content p {
  color: #fff !important;
}

@media screen and (max-width: 575.98px) {
  .pd-list .pd-list-content .pdl-count {
    font-size: 4rem;
  }
  .pd-list .pd-list-content .pdl-count span {
    font-size: 2rem;
  }
  .pd-list .pd-list-content p {
    font-size: 12px;
  }
}

.project_amenities {
  margin-top: 50px;
}
.project_amenities .pa-list {
  text-align: center;
  margin-bottom: 5rem;
}
.project_amenities .pa-list img {
  width: auto;
  margin: 0 auto;
}
.project_amenities .pa-list p {
  text-transform: uppercase;
  margin: 10px 0 0;
  line-height: 1.2;
}
.sl-aminities .item .pa-list:last-child {
  margin-bottom: 2rem;
}
.project_amenities .cta-db {
  text-align: center;
  margin-top: 1rem;
}
.project_amenities .slider-counter {
  opacity: 0;
}

.btn-be-secondary {
  background: #c99d3a;
  color: #fff;
  border: 1px solid #c99d3a;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
  padding: 10px 15px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

.btn-be-secondary:hover {
  color: #c99d3a;
  text-decoration: none;
  background-color: #fff;
}
.project-gallery.section {
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}
.pg-accordion {
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.pg-accordion ul {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}
.pg-accordion ul li {
  display: flex;
  vertical-align: bottom;
  position: relative;
  width: 25%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 500ms ease;
  overflow: hidden;
}
.pg-accordion ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  left: 0;
  top: 0;
  opacity: 0.4;
  transition: 0.3s;
}
.pg-accordion ul li p {
  display: inline-block;
  position: absolute;
  left: 20px;
  bottom: 20px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin: 0;
  opacity: 1;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}
.pg-accordion ul li p span {
  text-transform: none;
}
.pg-accordion ul li .pg-video {
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}
.pg-accordion ul li .pg-video video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.pg-accordion ul li {
  background-repeat: no-repeat;
  background-size: cover;
}

.project-gallery.section {
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}
.pg-accordion {
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.pg-accordion ul {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}
.pg-accordion ul li {
  display: flex;
  vertical-align: bottom;
  position: relative;
  width: 25%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 500ms ease;
  overflow: hidden;
}
.pg-accordion ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  left: 0;
  top: 0;
  opacity: 0.4;
  transition: 0.3s;
}
.pg-accordion ul li p {
  display: inline-block;
  position: absolute;
  left: 20px;
  bottom: 20px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin: 0;
  opacity: 1;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}
.pg-accordion ul li p span {
  text-transform: none;
}
.pg-accordion ul li .pg-video {
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}
.pg-accordion ul li .pg-video video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.pg-accordion ul li {
  background-repeat: no-repeat;
  background-size: cover;
}
.ojasvi .pg-accordion ul li:nth-child(1) {
  background-image: url(../public/images/Project_gallery/ojasvi_gallery01.jpg);
}
.ojasvi .pg-accordion ul li:nth-child(2) {
  background-image: url(../public/images/Project_gallery/ojasvi_gallery02.jpg);
}
.ojasvi .pg-accordion ul li:nth-child(3) {
  background-image: url(../public/images/Project_gallery/ojasvi_gallery03.jpg);
}
.ojasvi .pg-accordion ul li:nth-child(4) {
  background-image: url(../public/images/Project_gallery/ojasvi_gallery04.jpg);
}
.pg-accordion ul li.isOpen {
  width: 80%;
}
.pg-accordion li.isOpen::before {
  opacity: 0;
}
.pg-accordion ul li.isOpen .pg-video {
  opacity: 1;
}

.faqs-section .btn {
  background-color: #fff;
  border: 0px;
  color: #850b6f;
}

.be-accordion .accordion-item {
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
}
.be-accordion .accordion-item:last-child {
  border-bottom: 0;
}
.be-accordion .accordion-item .accordion-button {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding: 18px 0;
  box-shadow: none;
  border: 0px;
  background-color: transparent;
  text-align: left;
}

.be-accordion .accordion-item .accordion-button:hover,
.be-accordion .accordion-item .accordion-button:focus {
  color: #000;
}
.be-accordion .accordion-body {
  padding: 18px;
  border-top: 1px solid #dfdfdf;
}
.be-accordion .accordion-body strong,
.be-accordion.acc-secondary .accordion-body strong {
  font-weight: 500;
}
.be-accordion .accordion-body p,
.be-accordion.acc-secondary .accordion-body p {
  margin-bottom: 10px;
}
.be-accordion .accordion-body .acc-img,
.be-accordion.acc-secondary .accordion-body .acc-img {
  text-align: center;
  padding: 5px 0;
  max-width: 800px;
  margin: 0 auto;
}
.be-accordion .accordion-body .be-listing {
  margin-bottom: 10px;
}

.be-accordion.acc-secondary .accordion-item {
  background-color: #f4f4f4;
  margin-bottom: 10px;
  border-bottom: 0;
}
.be-accordion.acc-secondary.acc-light .accordion-item {
  background-color: #fbfbfb;
  margin-bottom: 5px;
}
.be-accordion.acc-secondary.acc-light .accordion-item .accordion-button {
  background: #fbfbfb;
}
.be-accordion.acc-secondary.acc-white .accordion-item {
  background-color: #fff;
  margin-bottom: 8px;
}
.be-accordion.acc-secondary.acc-white .accordion-item .accordion-button {
  background-color: #fff;
}
.be-accordion.acc-secondary .accordion-item:last-child {
  margin-bottom: 0;
}
.be-accordion.acc-secondary .accordion-item .accordion-button {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  background: #f4f4f4;
  box-shadow: none;
}
.be-accordion.acc-secondary .accordion-item .accordion-button:hover,
.be-accordion.be-accordion.acc-secondary
  .accordion-item
  .accordion-button:focus {
  color: #000;
}
.be-accordion.acc-secondary .accordion-body {
  padding-top: 0;
}
.be-accordion.acc-secondary .accordion-body .be-listing {
  margin-bottom: 10px;
}
.accordion-button::after {
  width: 30px;
  background-position-x: center;
}

.footer {
  padding: 40px;
  font-size: 13px;
  background-color: #00000018;
}

.modal-title {
  font-size: 30px;
}
.modal-title .new {
  font-weight: 400;
  font-size: 15px;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
